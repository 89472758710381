<template>
  <v-card flat>
    <v-container>
      <validation-provider v-slot="{ errors }" name="デモ日程" rules="">
        <v-chip-group v-model="scheduleType" active-class="deep-purple accent-4 white--text" :error-messages="errors" column dense>
          <v-chip :value="1" small>デモ日程</v-chip>
          <!-- <v-chip :value="2" small>制作・修正</v-chip>
                    <v-chip :value="4" small>その他</v-chip> -->
        </v-chip-group>
      </validation-provider>

      <!-- <pre>
                    {{schedules}}
                  </pre> -->

      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>

          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>日</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>週</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>月</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4日</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="value"
          :type="type"
          :events="schedules"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          :event-color="getEventColor"
          @change="onChange"
        ></v-calendar>
      </v-sheet>
    </v-container>
  </v-card>
</template>


<script>
export default {
  computed: {
    schedules() {
      return this.datas.map((a) => ({
        name: `${a.facility.name} ${a.demoTarget}`,
        start: this.$moment(a.demoSchedule).toDate(),
        end: this.$moment(a.demoSchedule).toDate(),
        color: a.facility.meta.color,
        timed: true,
      }));
    },
  },

  data: () => ({
    scheduleType: 1,

    datas: [],

    focus: '',
    type: 'month',

    typeToLabel: {
      month: '月',
      week: '週',
      day: '日',
      '4day': '4日',
    },

    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,

    mode: 'column',

    value: '',
    events: [],
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
  }),

  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = 'day';
    },

    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },

    onLoaded() {
      this.get('schedule', {}).then((success) => {
        let data = success.data;
        this.datas = data;

        // .then(() => {

        //    const events = [
        //     {facility:{name:"test"},data:new Date(2021,0,1),},
        //     {facility:{name:"[d]天城荘"},data:new Date(2021,8,1,15,30)},
        //     {facility:{name:"[R]アミ"},data:new Date(2021,8,5,10,30)},
        //     {facility:{name:"[S]久能山"},data:new Date(2021,8,16,14,0)},
        //   ];

        //   this.datas = events;
      });
    },

    onChange() {
      // const events = [
      //   {name:"test",start:new Date(2021,0,1),end:new Date(2021,0,2),color:this.colors[this.rnd(0, this.colors.length - 1)],timed:false},
      //   {name:"[d]天城荘",start:new Date(2021,8,1,15,30),end:null,color:this.colors[this.rnd(0, this.colors.length - 1)],timed:true},
      //   {name:"[R]アミ",start:new Date(2021,8,5,10,30),end:null,color:this.colors[this.rnd(0, this.colors.length - 1)],timed:true},
      //   {name:"[S]久能山",start:new Date(2021,8,16,14,0),end:null,color:this.colors[this.rnd(0, this.colors.length - 1)],timed:true},
      // ];
      // FIXME ここで絞り込む
      const events = this.schedules;

      this.events = events;
    },
    getEventColor(event) {
      return event.color;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
  watch: {},

  mounted() {
    this.onLoaded();
  },
};
</script>